<template>
  <p-container class="no-bg">
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-button type="primary" @click="handleadd">新增</a-button>
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
    </a-table>
    <a-modal v-model="updateVisible" title="专家信息" @ok="handleOk">
      <div>
        <a-input-group>
          <a-row :gutter="2">
            <a-col :span="24">
              <a-form-item label="专家姓名">
                <a-input v-model="zjxx.zjname" :disabled="false" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="所在单位">
                <a-input v-model="zjxx.dw" :disabled="false" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="从事领域">
                <a-input v-model="zjxx.csly" :disabled="false" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="出生日期:19xx-xx-xx">
                <a-input v-model="zjxx.csrq" :disabled="false" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="职称">
                <a-input v-model="zjxx.zc" :disabled="false" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="性别">
                <a-input v-model="zjxx.xb" :disabled="false" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </div>
      <div></div>
    </a-modal>
  </p-container>
</template>
<script>
import { getallxx, addzjmessage } from "../../../../../api/result";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    title: "专家姓名",
    dataIndex: "zjname",
    width: "10%",
  },
  {
    title: "所在单位",
    dataIndex: "dw",
  },
  {
    title: "从事领域",
    dataIndex: "csly",
  },
  {
    title: "数据日期",
    dataIndex: "sjrq",
  },
];
const dataSource = [
  {
    key: "0",
    name: "张三",
    company: "单位1",
    job: "从事1",
    date: "2021年1月1日",
  },
];
export default {
  name: "QuickSearch",
  data() {
    return {
      loading: false,
      treeData: [],
      rowSelection,
      dataSource,
      columns,
      updateVisible: false,
      zjxx: {},
      value: "",
    };
  },
  computed: {
    type() {
      return this.$route.name.substr(this.$route.name.lastIndexOf("_") + 1);
    },
    // showLeft(){
    //   return this.type!='all'
    // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
  mounted() {
    getallxx().then((res) => {
     
      this.dataSource = res.data;
    });
  },
  methods: {
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    webShowModal(col) {
      this.webVisible = true;
      this.mdl = { ...col };
    },
    handleOk(e) {
    
      this.updateVisible = false;
      var aData = new Date();
      this.value =
        aData.getFullYear() +
        "-" +
        (aData.getMonth() + 1) +
        "-" +
        aData.getDate();
      addzjmessage({
        zjname: this.zjxx.zjname,
        dw: this.zjxx.dw,
        csly: this.zjxx.csly,
        sjrq: this.value,
        xb: this.zjxx.xb,
        csrq: this.zjxx.csrq,
        zc: this.zjxx.zc,
      }).then((res) => {
        this.dataSource = res.data;
      });
    },
    handleadd() {
      this.updateVisible = true;
    },
  },
};
</script>

<style scoped></style>>
